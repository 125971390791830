import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom components
import Counter from "../components/Counter/Counter";
import ServicesCard from "../components/ServicesCard/ServicesCard";
import CustomNav from "../components/CustomNav/CustomNav";

// API
import { getHome } from "../api/home";

// Custom styles
import "../scss/pages/home.styles.scss";

// Assets
import bulletFigure from "../assets/images/symbol.svg";
import checkList from "../assets/images/afix-perks.jpeg";
import checkIcon from "../assets/images/check-icon.svg";
import phoneWhite from "../assets/images/phone--white.svg";
import emailWhite from "../assets/images/email--white.svg";

function Home() {
  const [loading, setLoading] = useState(false);
  const [homeData, sethomeData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getHome();
        if (response.status === 304) {
          sethomeData(result.docs[0]);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          sethomeData(result.docs[0]);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (
    loading &&
    Object.keys(homeData).length === 0 &&
    homeData.constructor === Object
  ) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  } else {
    return (
      <>
        <CustomNav transparent={true} disp={true} />
        <section
          className="hero__main"
          style={{
            backgroundImage: `url(https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run${
              new DOMParser().parseFromString(
                `<!doctype html><body>${homeData?.mainBackgroundImage?.url}`,
                "text/html"
              ).body.textContent
            })`,
          }}
        >
          <div className="container">
            {/* <h1>{title}</h1> */}
            <h1>{homeData?.title}</h1>
            <div className="d-flex">
              <img src={bulletFigure} alt="AFIX SERVICIOS FINANCIEROS" />
              <p>{homeData?.mainParagraph}</p>
              {/* <p>mainParagreaph</p> */}
            </div>
          </div>
        </section>
        <section className="about__home__wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h6>Nosotros</h6>
                <h4>{homeData?.aboutMainTitle}</h4>
                {homeData?.aboutDescription?.length >= 1 ? (
                  homeData?.aboutDescription?.map((description, key) => (
                    <p key={{ key }} className="mb-2">
                      {description?.children[0]?.text}
                    </p>
                  ))
                ) : (
                  // <p>{homeData?.aboutDescription[0].children[0]?.text}</p>
                  <p>null</p>
                )}
                {/* {console.log(homeData?.aboutDescription[0].children[0]?.text)} */}
                {/* <p>{aboutDescription[0].text}</p> */}
              </div>
              <div
                className="col-12 col-lg-6 d-flex align-items-center justify-content-center"
                // style={{ position: "relative" }}
              >
                <div className="circles__container">
                  {homeData?.stats?.map((stat, key) => (
                    <div key={key} className={`circle circle${key}`}>
                      <Counter finalValue={stat.individualStatistic}>
                        <span>+</span>
                      </Counter>

                      <p>{stat.caption}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <h6 className="services_title">Servicios Financieros</h6>
            <span id="line"></span>
            {/* {homeData?.serviceDescription?.type === "h5" ? (
              <h5>{homeData?.serviceDescription?.children[0]?.text}</h5>
            ) : (
              <p>{homeData?.serviceDescription?.children[0]?.text}</p>
            )} */}
            {/* {console.log(homeData?.serviceDescription)} */}
            {homeData?.serviceDescription?.length >= 1 &&
              (homeData?.serviceDescription[0]?.type === "h5" ? (
                <h5>{homeData?.serviceDescription[0]?.children[0]?.text}</h5>
              ) : (
                <p>{homeData?.serviceDescription[0]?.children[0]?.text}</p>
                // <p>holi</p>
              ))}
            <div className="services__cards">
              {homeData?.serviceCards?.map((serviceCard, key) => (
                <ServicesCard
                  key={key}
                  src={serviceCard.cardIcon.url}
                  name={serviceCard.cardTitle}
                  title={serviceCard.cardTitle}
                  text={serviceCard.cardDescription}
                />
              ))}
            </div>
          </div>
        </section>
        <section className="information">
          <div className="container-fluid">
            <div className="information__row">
              <div className="information__row__body">
                <h2 className="information__row__body__title">
                  {homeData?.marketsTitle}
                </h2>
                {homeData?.marketsDescription?.length >= 1 ? (
                  homeData?.marketsDescription?.map((description, key) => (
                    <p
                      className="information__row__body__text mb-2"
                      key={{ key }}
                      style={
                        description.children[0].italic
                          ? { fontStyle: "italic" }
                          : { fontStyle: "unset" }
                      }
                    >
                      {description.children[0].text}
                    </p>
                  ))
                ) : (
                  <p
                    className="information__row__body__text"
                    // style={
                    //   homeData?.marketsDescription?.children[0]?.italic === true
                    //     ? { fontStyle: "italic" }
                    //     : { fontStyle: "unset" }
                    // }
                    // style={description.children[0].italic ? fontStyle : "italic"}
                  >
                    {/* {homeData?.marketsDescription[0]?.children[0]?.text} */}
                    Sin texto
                  </p>
                )}

                <Link className="information__row__body__button" to="/mercados">
                  {" "}
                  Saber más
                </Link>
              </div>
              <div
                className="information__row__img"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_HOME_API_URL}${homeData?.marketsMedia?.url})`,
                }}
              ></div>
            </div>
          </div>
        </section>
        <section className="purpose">
          <div className="container">
            <div className="purpose__grid">
              <div className="purpose__grid__card">
                <h6 className="purpose__grid__card__name">Nuestra misión</h6>
                <h2 className="purpose__grid__card__title">
                  {homeData?.missionTitle}
                </h2>
                <p className="purpose__grid__card__text">
                  {homeData?.missionDescription}
                </p>
              </div>
              <div className="purpose__grid__card purpose__grid__card__green">
                <h6 className="purpose__grid__card__name name">
                  Nuestra visión
                </h6>
                <h2 className="purpose__grid__card__title">
                  {homeData?.visionTitle}
                </h2>
                <p className="purpose__grid__card__text">
                  {homeData?.visionDescription}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="perks_home__wrapper">
          <div className="perks_home__container">
            <div className="container">
              <h4>{homeData?.perksTitle}</h4>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="perks_home__container__media">
                    <img
                      src={`${process.env.REACT_APP_HOME_API_URL}${homeData?.perksMedia?.url}`}
                      alt=""
                      srcSet=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="perks_home__container__content">
                    <p
                      style={
                        homeData?.perksDescription?.italic
                          ? { fontStyle: "italic" }
                          : homeData?.perksDescription?.bold
                          ? { fontWeight: "bolder" }
                          : null
                      }
                    >
                      {/* {homeData?.perksDescription[0]?.children[0]?.text} */}
                      {homeData?.perksDescription?.length >= 1 ? (
                        homeData?.perksDescription?.map((description, key) => (
                          <p
                            key={{ key }}
                            style={
                              description.children[0].italic
                                ? { fontStyle: "italic" }
                                : { fontStyle: "unset" }
                            }
                          >
                            {description.children[0].text}
                          </p>
                        ))
                      ) : (
                        <p>Sin texto</p>
                      )}
                    </p>
                    <div className="perks_home__container__content__list">
                      {homeData?.perksPills?.map((perkPill, key) => (
                        <div
                          key={key}
                          className="perks_home__container__content__list__pill"
                        >
                          <img
                            src={checkIcon}
                            alt=""
                            srcSet=""
                            className="img-fluid"
                          />
                          <h6>{perkPill.pillTitle}</h6>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact">
          <div className="container">
            <h2 className="contact__title">
              Solicita cualquiera de nuestros créditos hoy mismo
            </h2>
            <div className="contact__row">
              <div className="contact__row__link">
                <div className="contact__row__link__button">
                  <div className="contact__row__link__button__row">
                    <img src={phoneWhite} alt="" />
                  </div>
                  <div className="contact__row__link__button__row">
                    <h5 className="contact__row__link__button__row__title">
                      Habla con un asesor:
                    </h5>
                    <a
                      href="tel:+523336301888"
                      className="contact__row__link__button__row__title"
                    >
                      (33)-36-30-18-88
                    </a>
                  </div>
                </div>
              </div>

              <div className="contact__row__link">
                <div className="contact__row__link__button">
                  <div className="contact__row__link__button__row">
                    <img src={emailWhite} alt="" />
                  </div>
                  <div className="contact__row__link__button__row">
                    <h5 className="contact__row__link__button__row__title">
                      Manda tu solicitud a:
                    </h5>
                    <a
                      href="mailto:hola@afix.com.mx"
                      className="contact__row__link__button__row__title"
                    >
                      hola@afix.com.mx
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Home;
