/*
  @route    GET https://REACT_APP_API_URL/home/654bf5f14db145a9cafef800?locale=en
  @desc     Get all products
  @access   Public
*/
export async function getHome() {
  try {
    const url = `https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run/api/home`;
    const response = await fetch(url, {
      method: "GET",
      // mode: "no-cors",
    });
    // console.log(response);
    const result = await response.json();
    // console.log(result);
    return { response, result };
  } catch (error) {
    console.log(error);
    return null;
  }
}
