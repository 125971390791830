/*
  @route    GET https://REACT_APP_API_URL/categories
  @desc     Get all categories for blog
  @access   Public
*/
export async function getCategories() {
  try {
    const url = `https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run/api/categories`;
    const response = await fetch(url, {
      method: "GET",
      // mode: "no-cors",
    });
    // console.log(response);
    const result = await response.json();
    // console.log(result);
    return { response, result };
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/posts
  @desc     Get all posts for blog
  @access   Public
*/
export async function getPosts() {
  try {
    const url = `https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run/api/posts`;
    const response = await fetch(url, {
      method: "GET",
      // mode: "no-cors",
    });
    // console.log(response);
    const result = await response.json();
    // console.log(result);
    return { response, result };
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/blog
  @desc     Get general info for blog
  @access   Public
*/
export async function getBlogContent() {
  try {
    const url = `https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run/api/blog`;
    const response = await fetch(url, {
      method: "GET",
      // mode: "no-cors",
    });
    // console.log(response);
    const result = await response.json();
    // console.log(result);
    return { response, result };
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  @route    GET https://REACT_APP_API_URL/posts/id
  @desc     Get general info for blog
  @access   Public
*/
export async function getArticleContent(id) {
  try {
    const url = `https://p01--afix-payload-mk2--w7xsf9cwbfgg.code.run/api/posts/${id}`;
    const response = await fetch(url, {
      method: "GET",
      // mode: "no-cors",
    });
    // console.log(response);
    const result = await response.json();
    // console.log(result);
    return { response, result };
  } catch (error) {
    console.log(error);
    return null;
  }
}
