import { useContext, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// Hooks
// import useFetch from "../../hooks/useFetch";

// Custom components
import { HashLink } from "react-router-hash-link";

// Custom styles
import "./customNav.styles.scss";

// Assets
import logoWhite from "../../assets/images/logo_AFIX--white.png";
import logoColor from "../../assets/images/logo_AFIX--color.png";

function CustomNav({ transparent, disp }) {
  // console.log(transparent);
  const [active, setActive] = useState(0);
  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.innerWidth > 739) {
      if (window.scrollY >= 50) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else {
      setActive(true);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);
  });

  function handleCollapse() {
    // console.log("handleCollapse");
    var nav = document.getElementById("basic-navbar-nav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  }
  return (
    <>
      <Navbar
        expand="lg"
        className={`custom fixed-top ${
          active && transparent ? "custom_nav__transparent_active" : "normal"
        } ${active && !transparent ? "custom_nav_active" : "normal"} ${
          disp ? "d-block" : ""
        } ${disp ? "showingNav" : "notShowingNav"}`}
      >
        <Container>
          <Navbar.Brand as={NavLink} to="/">
            {transparent ? (
              <img src={logoWhite} alt="AFIX SERVICIOS FINANCIEROS" />
            ) : (
              <img src={logoColor} alt="AFIX SERVICIOS FINANCIEROS" />
            )}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="border-0"
            id="navbarBtn"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className={`ms-auto custom__nav ${
                transparent ? "custom__nav__transparent" : ""
              }`}
            >
              <Nav.Link
                as={NavLink}
                to="/"
                eventKey={4}
                onClick={handleCollapse}
              >
                Inicio
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/nosotros"
                eventKey={4}
                onClick={handleCollapse}
              >
                Nosotros
              </Nav.Link>
              <HashLink
                to="/#services"
                className="nav-link"
                onClick={handleCollapse}
              >
                Servicios Financieros
              </HashLink>

              {/* <Nav.Link as={NavLink} to="/#services" onClick={handleCollapse}>
                Servicios Financieros
              </Nav.Link> */}
              <Nav.Link
                as={NavLink}
                to="/mercados"
                onClick={handleCollapse}
                eventKey={4}
              >
                Mercados
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/impacto-esg"
                onClick={handleCollapse}
                eventKey={4}
              >
                Impacto ESG
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/blog"
                onClick={handleCollapse}
                eventKey={4}
              >
                Blog
              </Nav.Link>
              <NavDropdown title="Acceso" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href="https://consultas.afix.com.mx/consultasApp/auth/consulta"
                  onClick={handleCollapse}
                >
                  Prospectos
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://app.afix.com.mx/login_usuarios.php"
                  onClick={handleCollapse}
                >
                  Clientes
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://app.worky.mx/iniciar-sesion"
                  onClick={handleCollapse}
                >
                  Colaboradores
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={NavLink}
                className="custom__nav_cta"
                to="/contacto"
                onClick={handleCollapse}
              >
                Contáctanos
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default CustomNav;

// <nav
//   className={`navbar navbar-expand-lg custom fixed-top ${
//     active && transparent ? "custom_nav__transparent_active" : "normal"
//   } ${active && !transparent ? "custom_nav_active" : "normal"}`}
// >
//   <div className="container">
//     <Nav.Link as={NavLink} to="/" className="navbar_brand">
//       {transparent ? (
//         <img src={logoWhite} alt="AFIX SERVICIOS FINANCIEROS" />
//       ) : (
//         <img src={logoColor} alt="AFIX SERVICIOS FINANCIEROS" />
//       )}
//     </Nav.Link>
//     <button
//       className="navbar-toggler border-0"
//       type="button"
//       data-bs-toggle="collapse"
//       data-bs-target="#navbarNav"
//       aria-controls="navbarNav"
//       aria-expanded="false"
//       aria-label="Toggle navigation"
//     >
//       <span
//         className={`navbar-toggler-icon ${
//           transparent ? "navbar_toggler_icon" : "navbar_toggler_icon__black"
//         }`}
//       ></span>
//     </button>
//     <div
//       className={"collapse navbar-collapse ml-auto navbar_collapse"}
//       id="navbarNav"
//     >
//       <ul
//         className={`navbar-nav ms-auto custom__nav ${
//           transparent ? "custom__nav__transparent" : ""
//         }`}
//       >
//         <li className="nav-item">
//           <Nav.Link as={NavLink} to="/">
//             Inicio
//           </Nav.Link>
//         </li>
//         <li className="nav-item">
//           <Nav.Link as={NavLink} to="/nosotros">
//             Nosotros
//           </Nav.Link>
//         </li>
//         <li className="nav-item">
//           <Nav.Link as={NavLink} to="/#services">
//             Servcios Financieros
//           </Nav.Link>
//         </li>
//         <li className="nav-item">
//           <Nav.Link as={NavLink} to="/mercados">
//             Mercados
//           </Nav.Link>
//         </li>
//         <li className="nav-item">
//           <Nav.Link as={NavLink} to="/impacto-esg">
//             Impacto ESG
//           </Nav.Link>
//         </li>
//         <li className="nav-item">
//           <Nav.Link as={NavLink} to="/blog">
//             Blog
//           </Nav.Link>
//         </li>
//         <NavDropdown title="Acceso" id="basic-nav-dropdown">
//           <NavDropdown.Item href="#action/3.1">Prospectos</NavDropdown.Item>
//           <NavDropdown.Item href="#action/3.3">Clientes</NavDropdown.Item>
//           <NavDropdown.Item href="#action/3.4">Colaboradores</NavDropdown.Item>
//         </NavDropdown>
//         {/* <li className="nav-item dropdown">
//         <a
//           className="nav-link dropdown-toggle"
//           href="#"
//           id="navbarDropdown"
//           role="button"
//           data-bs-toggle="dropdown"
//           aria-expanded="false"
//         >
//           Acceso
//         </a>
//         <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//           <li>
//             <a className="dropdown-item" href="#">
//               Prospectos
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Clientes
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Colaboradores
//             </a>
//           </li>
//         </ul>
//       </li> */}
//         <li className={"nav-item custom__nav_cta"}>
//           <Nav.Link as={NavLink} to="/contacto">
//             Contáctanos
//           </Nav.Link>
//         </li>
//       </ul>
//     </div>
//   </div>
// </nav>;
