import { useState, useEffect } from "react";

import { useForm } from "react-hook-form";

// API
import { getContactContent } from "../api/contact";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";

// Custom styles
import "../scss/pages/contact.styles.scss";

// Assets
import phoneWhite from "../assets/images/phone--white.svg";

const entidadesFederativas = [
  {
    nombre: "Aguascalientes",
  },
  {
    nombre: "Baja California",
  },
  {
    nombre: "Baja California Sur",
  },
  {
    nombre: "Campeche",
  },
  {
    nombre: "Chiapas",
  },
  {
    nombre: "Chihuahua",
  },
  {
    nombre: "Coahuila",
  },
  {
    nombre: "Colima",
  },
  {
    nombre: "Ciudad de México",
  },
  {
    nombre: "Durango",
  },
  {
    nombre: "Guanajuato",
  },
  {
    nombre: "Guerrero",
  },
  {
    nombre: "Hidalgo",
  },
  {
    nombre: "Jalisco",
  },
  {
    nombre: "México",
  },
  {
    nombre: "Michoacán",
  },
  {
    nombre: "Morelos",
  },
  {
    nombre: "Nayarit",
  },
  {
    nombre: "Nuevo León",
  },
  {
    nombre: "Oaxaca",
  },
  {
    nombre: "Puebla",
  },
  {
    nombre: "Querétaro",
  },
  {
    nombre: "Quintana Roo",
  },
  {
    nombre: "San Luis Potosí",
  },
  {
    nombre: "Sinaloa",
  },
  {
    nombre: "Sonora",
  },
  {
    nombre: "Tabasco",
  },
  {
    nombre: "Tamaulipas",
  },
  {
    nombre: "Tlaxcala",
  },
  {
    nombre: "Veracruz",
  },
  {
    nombre: "Yucatán",
  },
  {
    nombre: "Zacatecas",
  },
];

const information_sources = [
  "Facebook",
  "LinkedIn",
  "Instagram",
  "Evento Social",
  "Recomendación personal",
];

function Contact() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [contactData, setContactData] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const postForm = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/quotes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.log("fallo en post form");
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        throw new Error("Error en la solicitud");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
      // console.log("Respuesta de la API:", responseData);
      reset();
    } catch (error) {
      console.error("Error:", error);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      setLoading(false);
    }
    setSubmitted(true);
    window.scrollTo(0, 0);
    setTimeout(() => {
      setSubmitted(false);
    }, 3000);
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getContactContent();
        if (response.status === 304) {
          setContactData(result.docs[0]);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          setContactData(result.docs[0]);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (
    Object.keys(contactData).length === 0 &&
    contactData.constructor === Object
  ) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  }
  return (
    <section className="contact__wrapper">
      {/* {console.log(contactData)} */}
      <Alert
        variant={success ? "success" : "danger"}
        className={submitted ? "d-block" : "d-none"}
      >
        {success
          ? "Tu solicitud fue enviada con éxito. Un integrante de nuestro equipo te contactará pronto."
          : "Hubo un error en el envío de tu solicitud. Por favor, intenta de nuevo."}
      </Alert>
      <div
        className="hero"
        style={{
          backgroundImage: `url(
            ${process.env.REACT_APP_HOME_API_URL}${contactData.contactMedia.url}
          )`,
        }}
      ></div>
      <div className="contact__row2">
        <div>
          <form className="form" onSubmit={handleSubmit(postForm)}>
            <div className="form__Background">
              <h2 className="form__Background_tittle">{contactData.title}</h2>
              <h6 className="form__Background_subtitle">{contactData.text}</h6>
            </div>
            <div className="form__container">
              {/* iNPUT FULLNAME YA FUNCIONA */}
              <div className="form-group">
                <label htmlFor="nombre">Nombre Completo:</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.fullName
                      ? "is-invalid"
                      : watch("fullName")
                      ? "is-valid"
                      : ""
                  }`}
                  id="nombre"
                  {...register("fullName", {
                    required: "Este campo es requerido",
                  })}
                />

                {errors.fullName ? (
                  <span className="invalid-feedback">
                    {errors.fullName.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>
              {/* iNPUT PHONE YA FUNCIONA */}
              <div className="form-group">
                <label htmlFor="telefono">Teléfono:</label>
                <input
                  type="number"
                  className={`form-control ${
                    errors.phone
                      ? "is-invalid"
                      : watch("phone")
                      ? "is-valid"
                      : ""
                  }`}
                  id="telefono"
                  {...register("phone", {
                    required: "Este campo es requerido",
                  })}
                />

                {errors.phone ? (
                  <span className="invalid-feedback">
                    {errors.phone.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>
              {/* iNPUT EMAIL YA FUNCIONA */}
              <div className="form-group">
                <label htmlFor="correo">Correo:</label>
                <input
                  type="email"
                  className={`form-control ${
                    errors.email
                      ? "is-invalid"
                      : watch("email")
                      ? "is-valid"
                      : ""
                  }`}
                  id="correo"
                  {...register("email", {
                    required: "Este campo es requerido",
                  })}
                />

                {errors.email ? (
                  <span className="invalid-feedback">
                    {errors.email.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>
              {/* iNPUT STATE YA FUNCIONA */}
              <div className="form-group">
                <label htmlFor="entidadF">Entidad federativa:</label>
                <select
                  className={`form-control ${
                    errors.state
                      ? "is-invalid"
                      : watch("state")
                      ? "is-valid"
                      : ""
                  }`}
                  id="entidadF"
                  {...register("state", {
                    required: "Este campo es requerido",
                  })}
                >
                  <option value="" defaultValue>
                    Seleccione una opción
                  </option>
                  {entidadesFederativas.map((entidad, key) => (
                    <option value={entidad.nombre} key={key}>
                      {entidad.nombre}
                    </option>
                  ))}
                </select>
                {errors.state ? (
                  <span className="invalid-feedback">
                    {errors.state.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>
              {/* iNPUT PRODUCT YA FUNCIONA */}
              <div className="form-group">
                <label htmlFor="productosDeInteres">Producto de interés:</label>
                <select
                  className={`form-control ${
                    errors.product
                      ? "is-invalid"
                      : watch("product")
                      ? "is-valid"
                      : ""
                  }`}
                  id="productosDeInteres"
                  {...register("product", {
                    required: "Este campo es requerido",
                  })}
                >
                  <option value="" defaultValue>
                    Selecciona una opción
                  </option>
                  <option>Crédito Simple</option>
                  <option>Crédito Cuenta Corriente</option>
                  <option>Arrendamiento</option>
                </select>
                {errors.product ? (
                  <span className="invalid-feedback">
                    {errors.product.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="importe">Importe solicitado:</label>
                <input
                  type="number"
                  className={`form-control ${
                    errors.credit
                      ? "is-invalid"
                      : watch("credit")
                      ? "is-valid"
                      : ""
                  }`}
                  id="importe"
                  {...register("credit", {
                    required: "Este campo es requerido",
                  })}
                />
                {errors.credit ? (
                  <span className="invalid-feedback">
                    {errors.credit.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>

              <div className="form-group textArea">
                <label htmlFor="destinoFinanciamiento">
                  Destino del financiamiento:
                </label>
                <textarea
                  maxLength={150}
                  className={`form-control ${
                    errors.creditDestination
                      ? "is-invalid"
                      : watch("creditDestination")
                      ? "is-valid"
                      : ""
                  }`}
                  id="destinoFinanciamiento"
                  rows="3"
                  {...register("creditDestination", {
                    required: "Este campo es requerido",
                    maxLength: 150,
                  })}
                ></textarea>
                {errors.creditDestination ? (
                  <span className="invalid-feedback">
                    {errors.creditDestination.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="fuenteInformacion">
                  ¿Cómo te enteraste de nosotros?
                </label>
                <select
                  className={`form-control ${
                    errors.knownBy
                      ? "is-invalid"
                      : watch("knownBy")
                      ? "is-valid"
                      : ""
                  }`}
                  id="fuenteInformacion"
                  {...register("knownBy", {
                    required: "Este campo es requerido",
                  })}
                >
                  <option value="" defaultValue>
                    Selecciona una opción
                  </option>
                  {information_sources.map((source) => (
                    <option value={source} key={source}>
                      {source}
                    </option>
                  ))}
                </select>
                {errors.knownBy ? (
                  <span className="invalid-feedback">
                    {errors.knownBy.message}
                  </span>
                ) : (
                  <span className="valid-feedback">Correcto!</span>
                )}
              </div>

              <button type="submit" className="btn btn-primary submitButton">
                {loading ? "Enviando información..." : "Enviar"}
              </button>
            </div>
          </form>
        </div>
        <div>
          <div className="contactCardContainer">
            <div className="banner banner__quote">
              <h6>{contactData.greenBoxTitle}</h6>
              <p>{contactData.greenBoxDescription}</p>
              <a href="tel:+523336301888">
                <img src={phoneWhite} alt="" />
                <div>
                  <span>Habla con un asesor:</span>
                  <span>(33)-36-30-18-88</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
