import { useState, useEffect } from "react";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom components
import Markets from "../components/Markets/Markets";

// API
import { getMarkets } from "../api/markets";

// Custom styles
import "../scss/pages/markets.styles.scss";

// Assets
import checkIcon from "../assets/images/check-icon.svg";

function MarketsPage() {
  const [loading, setLoading] = useState(false);
  const [marketsData, setMarketsData] = useState({});
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getMarkets();
        if (response.status === 304) {
          setMarketsData(result.docs[0]);
        } else if (!response.ok) {
          console.error("Error fetching Markets :", response.ok);
        } else {
          setMarketsData(result.docs[0]);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (
    Object.keys(marketsData).length === 0 &&
    marketsData.constructor === Object
  ) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  }

  return (
    <>
      {/* {console.log(marketsData)} */}
      <section className="about">
        <div className="container-fluid">
          <div className="about__row">
            <div className="about__row__body">
              <h5 className="about__row__body_name">{marketsData.subTitle}</h5>
              <h2 className="about__row__body_title">{marketsData.title}</h2>
            </div>
            <div className="about__row__container">
              <img
                src={`${process.env.REACT_APP_HOME_API_URL}${marketsData.marketsMedia.url}`}
                // width={width}
                className="img-fluid about__row__container_img"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="marketsClass">
        <div className="container container_fluid_about">
          <h4 className="markets_name">Todos nuestros mercados:</h4>
          {/* <Tabs
            defaultActiveKey="profile"
            id="pills-tab"
            className="nav nav-pills mb-3 nav_pills"
          >
            {marketsData.markets.map(
              (market, index) => {
                const trimmedTitle = market.marketTitle.replace(/\s/g, "");

                return (
                  <Tab eventKey={trimmedTitle} title={trimmedTitle}>
                   
                    <img src={checkIcon} alt="" />
                    <h5 className="button_text">{trimmedTitle}</h5>
                  </Tab>
                );
              }
            )}
          </Tabs> */}
          <ul
            className="nav nav-pills mb-3 nav_pills"
            id="pills-tab"
            role="tablist"
          >
            {marketsData.markets.map((market, index) => {
              const trimmedTitle = market.marketTitle.replace(/\s/g, "");

              return (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link button"
                    id={index}
                    data-bs-toggle="pill"
                    data-bs-target={`#pill-${trimmedTitle}`}
                    type="button"
                    role="tab"
                    aria-controls={`pill-${trimmedTitle}`}
                    aria-selected="false"
                    onClick={() =>
                      // (document.location.href = `#${trimmedTitle}`)
                      {
                        const targetElement =
                          document.getElementById("pills-tabContent");

                        if (targetElement) {
                          targetElement.scrollIntoView({ behavior: "smooth" });
                        }
                      }
                    }
                  >
                    <img src={checkIcon} alt="" />
                    <h5 className="button_text ms-2 ms-lg-0">
                      {market.marketTitle.replace(/\s/g, " ")}
                    </h5>
                  </button>
                </li>
              );
            })}
          </ul>

          <div className="tab-content" id="pills-tabContent">
            {marketsData.markets.map((market, index) => {
              const trimmedTitle = market.marketTitle.replace(/\s/g, "");

              return (
                <Markets
                  key={index}
                  id={`pill-${trimmedTitle}`}
                  aLabelledby={`pill-${trimmedTitle}-tab`}
                  name={market.marketTitle}
                  marketShortDescription={market.marketShortDescription}
                  text={market.marketAbout}
                  beneficios={market.benefits}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default MarketsPage;
