import { useState, useEffect } from "react";

// API
import { getESG } from "../api/esgImpact";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom styles
import "../scss/pages/esgImpact.styles.scss";

// Assets
import leafOpacity from "../assets/images/leaf-opacity--green.svg";

function ESGImpact() {
  const [loading, setLoading] = useState(false);
  const [esgData, setESGData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { response, result } = await getESG();
        if (response.status === 304) {
          setESGData(result.docs[0]);
        } else if (!response.ok) {
          console.error("Error fetching home :", response.ok);
        } else {
          setESGData(result.docs[0]);
        }
      } catch (error) {
        console.error("Error fetching  data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (Object.keys(esgData).length === 0) {
    return (
      <Container style={{ marginTop: "90px" }}>
        <h6 className="text-center py-3">Cargando información...</h6>
      </Container>
    );
  }
  return (
    <div className="esg_wrapper">
      <section className="esg_hero">
        <div className="esg_description">
          <h5
            style={
              esgData?.esgDescription[0]?.children[0]?.bold === true
                ? { fontWeight: "bolder", fontFamily: "Rubik, sans-serif" }
                : { fontWeight: "lighter" }
            }
          >
            {esgData?.esgDescription[0]?.children[0]?.text}
          </h5>
          <h6
            style={
              esgData?.esgDescription[1]?.children[0]?.bold === true
                ? { fontWeight: "bolder", fontFamily: "Rubik, sans-serif" }
                : { fontWeight: "lighter" }
            }
          >
            {" "}
            {esgData?.esgDescription[1]?.children[0]?.text}
          </h6>
          <p>{esgData?.esgDescription[2]?.children[0]?.text}</p>
          <p>{esgData?.esgDescription[3]?.children[0]?.text}</p>
        </div>
        <div className="esg_hero__media">
          <div className="esg_hero__media__heading">
            <h3>{esgData?.title}</h3>
            <img src={leafOpacity} alt="" />
          </div>
          <div className="esg_hero__media__image">
            <img
              src={`${process.env.REACT_APP_HOME_API_URL}${esgData?.EsgHero.url}`}
              alt=""
            />
          </div>
          <div className="esg_hero__media__stats">
            <div>
              <div>
                <h4>+{esgData?.treesStat}</h4>
                <span>{esgData?.treesText}</span>
              </div>
              <p>{esgData?.treesDescription}</p>
            </div>
            <div>
              <p>{esgData?.description}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="esg_onu">
        <div>
          <h6>{esgData?.ONUDescription[0]?.children[0]?.text}</h6>
          <h6 className="mt-3">
            {esgData?.ONUDescription[1].children[0]?.text}
          </h6>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_HOME_API_URL}${esgData?.ONUMedia.url}`}
            alt=""
          />
        </div>
      </section>
      <section className="esg_impact">
        <div className="esg_impact__heading">
          <div className="d-flex">
            <img
              src={`${process.env.REACT_APP_HOME_API_URL}${esgData?.clientImpactMedia.url}`}
              alt=""
            />
            <div className="d-flex flex-column">
              <h4>{esgData?.clientImpactTitle}</h4>
              <p className="my-3">{esgData?.clientImpactDescription}</p>
            </div>
          </div>
          <div className="esg_impact__heading__card">
            <p>{esgData?.benefitsDescription}</p>
          </div>
        </div>
        <div className="esg_impact__benefits">
          {esgData?.benefits.map((item2, key) => (
            <div className="esg_impact__benefit" key={key}>
              <div className="esg_impact__benefit_heading">
                <img
                  src={`${process.env.REACT_APP_HOME_API_URL}${item2.benefitMedia.url}`}
                  alt=""
                />
                <p>{item2.benefitTitle}</p>
              </div>
              <div className="esg_impact__benefit_body">
                <p key={key}>{item2.benefitDescription[0].children[0]?.text}</p>
                {/* {esgData?.benefits?.map(
                  (item, key) =>
                    item.type === "ul" ? (
                      <ul key={key}>
                        {item.children.map((liItem, key) => (
                          <li key={key}>{liItem.children[0]?.text}</li>
                        ))}
                        {console.log(item)}
                      </ul>
                    ) : (
                      <p key={key}>
                        {item.benefitDescription[0].children[0]?.text}
                      </p>
                      // <p>holi</p>
                    )
                  // {
                  //   console.log(item);
                  // }
                )} */}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default ESGImpact;
