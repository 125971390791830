import React from "react";

// Custom styles
import "../scss/pages/privacy.styles.scss";

function Privacy() {
  return (
    <div className="privacy_notice__wrapper">
      <section className="privacy_notice__container">
        <h2 className="text-center">Aviso de Privacidad</h2>
        <p>
          “AFIX SERVICIOS FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR con domicilio
          en Avenida Américas número 1930 un mil novecientos treinta Piso 10
          diez, UP 2 dos, Colonia Country Club, en Guadalajara, Jalisco, Código
          Postal 44610 cuarenta y cuatro mil seiscientos diez, es responsable de
          los datos personales que recaba y en respeto al derecho de privacidad
          y a la autodeterminación informativa de las personas en cumplimiento
          con lo dispuesto en la Ley de Protección de Datos Personales en
          Posesión de Particulares y su reglamento, atendiendo los principios de
          licitud, consentimiento, información, calidad, finalidad, lealtad,
          proporcionalidad y responsabilidad.
        </p>
        <h4 className="text-center">MEDIOS DISPONIBLES DE COMUNICACIÓN.</h4>
        <ul>
          <li>
            Correo electrónico:{" "}
            <a href="mailto:informes@afix.com.mx">informes@afix.com.mx</a>
          </li>
          <li>
            Domicilio: Avenida Américas número 1930 un mil novecientos treinta
            Piso 10 diez, UP 2 dos, Colonia Country Club, en Guadalajara,
            Jalisco, Código Postal 44610 cuarenta y cuatro mil seiscientos diez.
          </li>
          <li>
            Teléfono: <a href="tel:+523336301888">(33) 36-30-18-88</a>
          </li>
        </ul>
        <p>
          “AFIX SERVICIOS FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR recaba y da
          tratamiento a los siguientes datos personales y datos patrimoniales de
          manera lícita, otorgando su consentimiento tácito siempre y cuando
          usted no manifieste objeción alguna en el momento que nos proporciona
          sus datos personales directamente o manifestar su oposición posterior
          en nuestros medios descritos en la sección anterior, con solo
          enviarnos en escrito libre que exprese su deseo de revocarnos su
          consentimiento en cualquier momento.
        </p>
        <p>
          Como cliente de alguno de nuestros servicios le podemos solicitar
          información personal, que varía según el caso, relativa a:
        </p>
        <h4 className="text-center">DATOS PERSONALES</h4>
        <ul className="columns_2">
          <li>Nombre</li>
          <li>Nacionalidad</li>
          <li>Firma</li>
          <li>RFC</li>
          <li>Domicilio particular</li>
          <li>Huella digital</li>
          <li>Folio IFE</li>
          <li>Clave de elector</li>
          <li>Número de pasaporte</li>
          <li>Número de cédula profesional</li>
          <li>Número de teléfono particular</li>
          <li>Número de servicio CFE</li>
          <li>Medidor CFE</li>
          <li>CURP</li>
          <li>Correo electrónico</li>
          <li>Puesto laboral</li>
          <li>Número de teléfono celular</li>
          <li>Estado civil</li>
          <li>Número de contrato</li>
          <li>Género</li>
        </ul>
        <p>
          En la recolección y tratamiento de datos personales que usted nos
          proporcione, cumplimos todos los principios que marca LEY FEDERAL DE
          PROTECCIÓN DE DATOS PERSONALES (artículo 6): licitud, calidad,
          consentimiento, información, finalidad, lealtad, proporcionalidad y
          responsabilidad. Los datos personales que recopilamos los utilizamos
          únicamente para fines comerciales y de manera enunciativa mas no
          limitativa para los siguientes propósitos:
        </p>
        <ul>
          <li>Identificación, verificación y contacto.</li>
          <li>
            Promoción de contratos comerciales y documentación integrante.
          </li>
          <li>Cumplimiento de la legislación aplicable.</li>
        </ul>
        <h4 className="text-center">
          ¿QUIÉN ES EL RESPONSABLE DE SUS DATOS PERSONALES?
        </h4>
        <p>
          “AFIX SERVICIOS FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR Avenida
          Américas número 1930 un mil novecientos treinta Piso 10 diez, UP 2
          dos, Colonia Country Club, en Guadalajara, Jalisco, Código Postal
          44610 cuarenta y cuatro mil seiscientos diez, es responsable del
          tratamiento de sus datos personales.
        </p>
        <h4 className="text-center">TRANSFERENCIA</h4>
        <p>
          Los datos personales pueden ser transferidos y tratados a los terceros
          debajo enunciados. Todas las finalidades mencionadas están en los
          supuestos del art. 37 de la ley, por lo que no es necesario obtener el
          consentimiento para su transferencia:
        </p>
        <table className="table">
          <thead>
            <tr>
              <th className="table-success text-center" scope="col">
                TERCERO
              </th>
              <th className="table-success text-center" scope="col">
                FINALIDAD
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Fedatarios públicos y consultores legales.</th>
              <td>
                Gestión de contratos comerciales y documentación integrante.
              </td>
            </tr>
            <tr>
              <th scope="row">Instituciones Bancarias.</th>
              <td>Pagos y depósitos en relación al contrato comercial.</td>
            </tr>
            <tr>
              <th scope="row">Dependencias de gobierno.</th>
              <td colSpan="2">Cumplimiento de leyes aplicables.</td>
            </tr>
            <tr>
              <th scope="row">Aseguradoras.</th>
              <td colSpan="2">Trámite de seguros integrantes del contrato.</td>
            </tr>
            <tr>
              <th scope="row">Buró de crédito.</th>
              <td colSpan="2">Análisis y gestión del contrato comercial.</td>
            </tr>
          </tbody>
        </table>
        <p>
          Por los medios que se ponen a su disposición en el apartado “MEDIOS
          DISPONIBLES DE COMUNICACIÓN” con gusto recibiremos su solicitud de
          negativa al tratamiento de sus datos personales. La información
          detallada del uso de los anteriores datos personales podrá ser
          provista previa solicitud formal del titular mediante los mecanismos
          legales para ejercer los derechos ARCO descrito más adelante en la
          sección del mismo nombre.
        </p>
        <h4 className="text-center">DATOS PERSONALES SENSIBLES</h4>
        <p>
          “AFIX SERVICIOS FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR recaba los
          siguientes datos personales sensibles de manera lícita: ingresos
          económicos, número de cuentas bancarias y valor patrimonial.
        </p>
        <p>
          De acuerdo al art. 40,41 y 42 del reglamento los anteriores datos
          personales serán utilizados para las siguientes finalidades necesarias
          para establecer la relación jurídica con la organización.
        </p>
        <ul>
          <li>Gestión de contratos comerciales y documentación integrante.</li>
          <li>Cumplimiento de obligaciones.</li>
        </ul>
        <h4 className="text-center">
          TRANSFERENCIA DE LOS DATOS PERSONALES SENSIBLES
        </h4>
        <p>
          Los datos personales sensibles descritos son transferidos a terceros
          particulares y dependencias de gobierno para las finalidades
          contenidas en el Art. 37 de la Ley:
        </p>
        <table className="table">
          <thead>
            <tr>
              <th className="table-success text-center" scope="col">
                TERCERO
              </th>
              <th className="table-success text-center" scope="col">
                FINALIDAD
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Dependencias de gobierno.</th>
              <td colSpan="2">Cumplimiento de leyes aplicables.</td>
            </tr>
          </tbody>
        </table>
        <h4 className="text-center">FINALIDADES NO NECESARIAS.</h4>
        <p>
          El Titular podrá negarse al tratamiento de sus Datos Personales para
          las finalidades no necesarias, enviando un correo a{" "}
          <a href="mailto:informes@afix.com.mx">informes@afix.com.mx</a>, en el
          entendido de que, en caso de no recibir la solicitud correspondiente,
          se entenderá por consentido el uso de los Datos Personales para las
          finalidades antes mencionadas, son consideradas como “finalidades no
          necesarias” las siguientes:
        </p>
        <ul>
          <li>
            Envío de ofertas, promociones, mercadotecnia o eventos especiales
            que puedan ser de interés del Titular.
          </li>
          <li>
            Enviar los datos de contacto del Titular a los socios comerciales y
            a los clientes de estos a efecto de ponerlos en contacto con el
            Titular respecto de los productos y/o servicios que éste ofrece;
          </li>
          <li>Promoción de servicios y/o prospección comercial.</li>
          <li>
            Enviar el boletín de noticias y ofertas, así como beneficios y
            servicios ofrecidos por socios comerciales.
          </li>
        </ul>
        <h4 className="text-center">PLAZOS DE CONSERVACIÓN.</h4>
        <p>
          “AFIX SERVICIOS FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR. procederá a
          cancelar y efectuar la supresión previo bloqueo de los datos
          personales una vez que cumplan con la finalidad que justificó su
          tratamiento.
        </p>
        <p>
          La cancelación de datos personales no será realizada cuando le sean
          aplicables los supuestos de los artículos 26 y 34 de la Ley
        </p>
        <h4 className="text-center">
          EJERCICIO DE LOS DERECHOS ARCO. (ACCEDER, RECTIFICAR, CANCELAR U
          OPONERSE AL USO DE DATOS PERSONALES)
        </h4>
        <p>
          Usted tendrá́ en todo momento el acceso a sus Datos Personales ya sea
          para solicitar su rectificación, cancelación u oposición de
          conformidad con lo dispuesto en la LEY FEDERAL DE PROTECCIÓN DE DATOS
          PERSONALES, en forma escrita, electrónica o telefónica, dirigiendo su
          mensaje genéricamente a AFIX SERVICIOS FINANCIEROS, S.A.P.I. de C.V.
          SOFOM ENR. a la dirección de correo electrónico:{" "}
          <a href="mailto:informes@afix.com.mx">informes@afix.com.mx</a>, o al
          teléfono <a href="tel:+523336301888">01 (33)36-30-18-88</a>.
        </p>
        <p>
          Para lo anterior, deberá hacernos saber los Datos Personales que usted
          desea sean rectificados, cancelados o reservados, así́ como el
          propósito para el cual los aportó y el nombre del responsable o área a
          quien se los entregó y en general cumplir los requisitos mencionados
          en el art. 29 de LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES.
        </p>
        <p>
          Recibiremos la solicitud con la siguiente información en documento
          físico o electrónico utilizando los medios que ponemos a su
          disposición descritos en el apartado “MEDIOS DISPONIBLES DE
          COMUNICACIÓN” en la parte superior de este aviso.
        </p>
        <ul>
          <li>
            Nombre completo y domicilio, así como cualquier información
            complementaria que sirva para contactarle.
          </li>
          <li>Documentos que acrediten la identidad.</li>
          <li>
            Descripción concisa y clara que describa los derechos que se desean
            ejercer, así como los datos personales involucrados.
          </li>
          <li>
            En el caso de solicitar rectificación de datos personales, deberá
            acompañar la documentación que sustente la petición.
          </li>
          <li>
            Señalar claramente datos de contacto del titular, siendo importante
            señalar que en el supuesto de que sea necesario él envió de los
            documentos en físico, los gastos generados correspondientes serán a
            cargo del titular solicitante.
          </li>
        </ul>
        <p>
          La respuesta a su solicitud se le hará llegar dentro de los plazos que
          marca la Ley siempre y cuando no se encuentren bajo los supuestos de
          excepción del artículo 26 de la ley y 75 del reglamento.
        </p>
        <h4 className="text-center">
          MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES:
        </h4>
        <p>
          “AFIX SERVICIOS FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR, podrá negar
          el ejercicio de Derechos ARCO en los supuestos que lo permita la Ley y
          el Reglamento.
        </p>
        <p>
          El Titular podrá revocar su consentimiento para el tratamiento de sus
          Datos Personales, sin efectos retroactivos, en todos aquellos casos en
          que dicha revocación no suponga la imposibilidad de cumplir
          obligaciones derivadas de una relación jurídica vigente entre el
          Titular y cualquiera de los responsables. El procedimiento para la
          revocación del consentimiento, en su caso, será el mismo que el
          establecido en el apartado para el ejercicio de los Derechos ARCO.
        </p>
        <h4 className="text-center">REVOCACION DEL CONSENTIMIENTO</h4>
        <p>
          De acuerdo con el artículo 21 del reglamento, en todo momento usted
          podrá revocar el consentimiento que nos ha otorgado para el
          tratamiento de sus datos personales, a fin de que dejemos de hacer uso
          y transferencia de estos mediante la utilización de los mecanismos
          provistos para ello en este documento.
        </p>
        <p>
          De igual manera es necesario que nos haga llegar su petición por los
          medios mencionados en este aviso y que deberá ir acompañada de la
          siguiente información:
        </p>
        <ul>
          <li>
            Nombre y domicilio, así como cualquier información complementaria
            que sirva para contactarle.
          </li>
          <li>Documentos que acrediten la identidad.</li>
        </ul>
        <h4 className="text-center">
          USO DE COOKIES, WEB BEACONS Y OTRAS TECNOLOGÍAS SIMILARES:
        </h4>
        <p>
          Se hace del conocimiento del Titular, que “AFIX SERVICIOS
          FINANCIEROS”, S.A.P.I. de C.V. SOFOM ENR utiliza mecanismos remotos o
          locales de comunicación electrónica, óptima u otra tecnología que
          permiten recabar Datos Personales relacionados con su actividad en los
          Medios Electrónicos y los Servicios.
        </p>
        <p>
          Las Cookies se utilizan para mejorar la eficiencia del uso del portal
          Web de la Institución. Las Cookies son identificadores que nuestro
          servidor le puede enviar a su computadora para identificar el equipo
          que ha sido utilizado durante la sesión. La mayoría de los
          exploradores de Internet están diseñados para aceptar estas Cookies
          automáticamente.
        </p>
        <p>
          Adicionalmente, usted podrá desactivar el almacenamiento de Cookies o
          ajustar su explorador de Internet para que le sea informado antes que
          las Cookies se almacenen en su computadora. Las Cookies pueden ser
          depuradas por usted de forma manual en cuanto usted lo decida.
        </p>
        <h4 className="text-center">MODIFICACIONES AL AVISO DE PRIVACIDAD.</h4>
        <p>
          Nos reservamos el derecho de efectuar en cualquier momento
          modificaciones o actualizaciones al presente aviso de privacidad, para
          la atención de novedades legislativas, políticas internas o nuevos
          requerimientos para la prestación u ofrecimiento de nuestros servicios
          o productos
        </p>
        <p>
          Estas modificaciones estarán disponibles al público a través de la
          página de internet que a continuación se menciona:{" "}
          <a
            href="http://www.afix.com.mx"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.afix.com.mx
          </a>
        </p>
      </section>
    </div>
  );
}

export default Privacy;
