import { Link } from "react-router-dom";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

// Custom styles
import "./footer.styles.scss";

// Assets
import waIconGreen from "../../assets/images/wa-icon--green.svg";
import fbGreenIcon from "../../assets/images/facebook--green.svg";
import igGreenIcon from "../../assets/images/ig-icon--green.svg";
import linkedGreenIcon from "../../assets/images/linkedin-icon--green.svg";
import UNE from "../../assets/images/UNE.png";
import Condusef from "../../assets/images/Condusef.png";
import Buro from "../../assets/images/Buro.png";
import Asofom from "../../assets/images/Asofom.png";
import CNBV from "../../assets/images/CNBV.png";

function Footer() {
  return (
    <footer className="footer">
      <a
        href="https://wa.me/+523331582842"
        className="btn_float"
        target="_blank"
        rel="noreferrer"
      >
        <img src={waIconGreen} alt="AFIX SERVICIOS FINANCIEROS" />
      </a>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-2">
            <div className="footer__main">
              <h6>¡Síguenos en redes!</h6>
              <div>
                <a
                  href="https://www.facebook.com/AFIXFinanciera"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fbGreenIcon} alt="AFIX SERVICIOS FINANCIEROS" />
                </a>

                <a
                  href="https://www.instagram.com/afixfinanciera"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={igGreenIcon} alt="AFIX SERVICIOS FINANCIEROS" />
                </a>
                <a
                  href="https://www.linkedin.com/company/afixfinanciera"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedGreenIcon} alt="AFIX SERVICIOS FINANCIEROS" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mt-4 mt-lg-0">
            <div className="footer__contact">
              <ul>
                <span>Oficina corporativa</span>
                <li>
                  <a
                    href="https://maps.app.goo.gl/ernUY12YkWS7FZ4n6"
                    target="_blank"
                    rel="noreferrer"
                    className="w-90"
                    style={{ display: "block" }}
                  >
                    Av. Américas 1930. Piso 10 UP-2 Col. Country Club. C.P.
                    44610. Guadalajara, Jalisco. MX.
                  </a>
                </li>
                <li>
                  <a href="tel:+523336301888">(33)-36-30-18-88</a>
                </li>
                <li>
                  <a href="mailto:hola@afix.com.mx">hola@afix.com.mx</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0">
            <h6 style={{ color: "#fff" }}>Regulación y supervisión</h6>
            <div className="footer__logos__container">
              <div className="footer__logos">
                <Link to="/unidad-especializada-de-atencion-a-clientes">
                  <img src={UNE} alt="UNE" />
                </Link>
                <Link to="/condusef" className="condusef">
                  <img
                    src={Condusef}
                    alt="Condusef"
                    style={{ width: "50px" }}
                  />
                </Link>
                <img src={Buro} alt="Buro" />
                <a
                  href="https://asofom.mx/region-occidente/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Asofom} alt="Asofom" />
                </a>
                <img src={CNBV} alt="CNBV" />
              </div>
            </div>
            <div className="footer__logos__links">
              <Link to="/aviso-de-privacidad">Aviso de Privacidad</Link>
              <a
                href="https://webappsos.condusef.gob.mx/EducaTuCartera/consejos.html"
                target="_blank"
                rel="noreferrer"
              >
                Educación Financiera
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="footer__regulatory">
          <h6>Esta SOFOM se encuentra regulada por la CONDUSEF.</h6>
          <p className="my-2">
            AFIX SERVICIOS FINACIEROS, al ser una SOFOM E.N.R. y de conformidad
            al artículo 87-J de la Ley General de Organizaciones y Actividades
            Auxiliares del Crédito, no requiere autorización de la Secretaría de
            Hacienda y Crédito Público para su constitución y operación, y está
            sujeta a la supervisión o vigilancia de la Comisión Nacional
            Bancaria y de Valores.
          </p>
          <p>
            AFIX SERVICIOS FINANCIEROS, de acuerdo con el Articulo 95-bis de la
            Ley General de Organizaciones y Actividades Auxiliares del Crédito
            es vigilada y supervisada por la Comisión Nacional Bancaria y de
            Valores para el adecuado cumplimiento de las disposiciones
            relacionadas con la Prevención de Operaciones con Recursos de
            Procedencia Ilícita y Financiamiento al Terrorismo.
          </p>
        </div>
      </div>
      <div className="container-fluid">
        <div className="footer__bottom">
          <p>
            Todos los derechos reservados 2023. AFIX Servicios Financieros SAPI
            de C.V. SOFOM ENR
          </p>
          <p>
            Diseñado y desarrollado por{" "}
            <a
              href="https://quintech.mx"
              target="_blank"
              rel="noopener noreferrer"
            >
              QuinTech
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
